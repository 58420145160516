import { CLICK_TRACKING_API_URL } from "../../config";
import { useQuery } from "@apollo/client";
import Jobs from "../../components/Jobs";
import VideoCard from "../../components/VideoCard";
import FeaturedVideoCard from "../../components/FeaturedVideoCard";
import HeroBanner from "../../components/HeroBanner";
import BlueSlider from "../../components/BlueSlider";
import BigCardSquare from "../../components/BigCardSquare";
import ProductSection from "../../components/ProductSection";
import BigCardVertical from "../../components/BigCardVertical";
import LocationsSlider from "../../components/LocationsSlider";
import GreyCardVertical from "../../components/GreyCardVertical";
import GreyCardHorizontal from "../../components/GreyCardHorizontal";
import SmallCardHorizontal from "../../components/Small-Card-Horizontal";
import Slider from "../../components/Slider";

import { BEAUTYSTARS_DATA, STICKY_DATA } from "./querys";

import style from "./style.module.scss";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import PromoCodeBanner from "../../components/PromoCodeBanner";
import PromoCodeBanner2 from "../../components/PromoCodeBanner2";

import { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import SwiperCore, { EffectCoverflow } from "swiper";
SwiperCore.use([EffectCoverflow]);

export default function BeautyStar() {
  const { data, loading } = useQuery(BEAUTYSTARS_DATA);
  const activeTab = useSelector((state) => state.navbarReducer.active);

  const {
    data: stickyData,
    loading: stickyLoading,
    error,
  } = useQuery(STICKY_DATA);

  const userId = useSelector((state) => state.authReducer.id);

  const swiperRef = useRef();

  if (loading || stickyLoading) {
    return null;
  }

  const categories = {};
  const specialCategories = {};

  data?.categories?.nodes?.forEach((category) => {
    categories[category.slug] = category;
  });

  stickyData?.categories?.nodes?.forEach((category) => {
    specialCategories[category.slug] = category;
  });

  const posts = data.posts?.nodes?.filter((post) =>
    post.categories.edges.some(
      (category) =>
        category.node.imagecategory.caddysbeautystar === activeTab ||
        category.node.imagecategory.caddysbeautystar === "caddysbeautystar"
    )
  );

  const inEvidenza = stickyData.contentNodes.edges[0].node;

  // const stickyPosts = stickyData.contentNodes.edges.filter((edge) => {
  //   return edge?.node?.contentType?.node?.name === 'post';
  // });

  const stickyVideos = stickyData.videos.edges;

  const newProducts = [];

  data.banners.nodes.forEach((banner, index) => {
    if (banner.page == "BeautyStar" && banner.section == "top") {
      newProducts[index] = banner;
    }
  });

  return (
    <div className={style.container}>
      {/* <PromoCodeBanner2/> */}

      <HeroBanner
        image="/assets/images/beautystar.png"
        imageMobile="/assets/images/beautystarMobile.png"
      >
        <div className="container">
          <div className="row">
            <div className="col-6 col-lg-3">
              <div className={style.bannerContent}>
                <img
                  src="/assets/svgs/house.svg"
                  alt="Icon"
                  className={style.bannerIcon}
                />
                <p className={style.title}>67</p>
                <p className={style.description}>Negozi Beauty Star</p>
              </div>
            </div>
            <div className="col-6 col-lg-3">
              <div className={style.bannerContent}>
                <img
                  src="/assets/svgs/user.svg"
                  alt="Icon"
                  className={style.bannerIcon}
                />
                <p className={style.title}>245</p>
                <p className={style.description}>
                  Dipendenti negozi Beauty Star
                </p>
              </div>
            </div>
            <div className="col-6 mt-2 lg-mt-5 col-lg-3 mt-lg-0">
              <div className={style.bannerContent}>
                <img
                  src="/assets/svgs/warehouse.svg"
                  alt="Icon"
                  className={style.bannerIcon}
                />
                <p className={style.title}>40.000</p>
                <p className={style.description}>Mq Centro logistico</p>
              </div>
            </div>
            <div className="col-6 mt-2 lg-mt-5 col-lg-3 mt-lg-0">
              <div className={style.bannerContent}>
                <img
                  src="/assets/svgs/globe.svg"
                  alt="Icon"
                  className={style.bannerIcon}
                />
                <p className={style.title}>3.500</p>
                <p className={style.description}>Mq Headquarter</p>
              </div>
            </div>
          </div>
        </div>
      </HeroBanner>
      <div className="container">
        <div className={`${style.horizontalGrid} ${style.largeMargin}`}>
          <div className={style.horizontalGridSection}>
            <div className={style.doubleSideFlex}>
              <h2 className={style.sectionTitle}>In evidenza</h2>
            </div>
            <div>
              {inEvidenza && inEvidenza.contentType.node.name === "post" && (
                <BigCardSquare data={inEvidenza} />
              )}

              {inEvidenza && inEvidenza.contentType.node.name === "video" && (
                <FeaturedVideoCard data={inEvidenza} />
              )}
            </div>
          </div>
          <div className={`${style.horizontalGridSectionFlex} mt-5 mt-lg-0`}>
            <div className={style.doubleSideFlex}>
              <h2 className={style.sectionTitle}>Ultimi articoli</h2>
              <Link className={style.moreArticles} to="/ultimi-articoli">
                Vedi altri articoli
              </Link>
            </div>
            <div className={style.verticalFlex}>
              {posts
                .filter((post) => post?.databaseId !== inEvidenza?.databaseId)
                .slice(0, 3)
                .map((data) => (
                  <SmallCardHorizontal data={data} key={data.slug} />
                ))}
            </div>
          </div>
        </div>
      </div>
      {/* <div className={style.largeMargin}>
        <div className="container">
          <Link to="/video">
            <img
              src="/assets/images/christmas-banner-mobile.jpeg"
              className="d-lg-none d-block w-100"
              alt="christmas banner mobile"
            />
            <img
              src="/assets/images/christmas-banner-desktop.jpeg"
              className="d-none d-lg-block w-100"
              alt="christmas banner desktop"
            />
          </Link>
        </div>
      </div> */}
      {data.locations.nodes.length > 0 && (
        <div className={style.largeMargin}>
          <LocationsSlider data={data.locations.nodes} />
        </div>
      )}
      {newProducts.length > 0 ? (
        <div className={style.largeMargin}>
          <div className="container">
            <div className={style.doubleSideFlex}>
              <h2 className={style.sectionTitle}>Nuovi prodotti</h2>
            </div>
            <div className={style.sliderContainer}>
              <div
                className={`${style.iconContainer} ${style.leftIcon}`}
                onClick={() => swiperRef.current.swiper.slidePrev()}
              >
                <svg className={style.icon} viewBox="0 0 24 24">
                  <path d="M10.6,12.71a1,1,0,0,1,0-1.42l4.59-4.58a1,1,0,0,0,0-1.42,1,1,0,0,0-1.41,0L9.19,9.88a3,3,0,0,0,0,4.24l4.59,4.59a1,1,0,0,0,.7.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.42Z" />
                </svg>
              </div>
              <div className={style.sliderContent}>
                <Swiper
                  className={style.sliderWrapper}
                  speed={1000}
                  breakpoints={{
                    992: {
                      slidesPerView: 3,
                      spaceBetween: 0,
                    },
                  }}
                  grabCursor={true}
                  centeredSlides={true}
                  loop={true}
                  spaceBetween={24}
                  pagination={true}
                  ref={swiperRef}
                >
                  {newProducts.map((product, index) => (
                    <SwiperSlide key={index}>
                      <Link
                        to={product.follow}
                        className={`${style.imageContainer} d-block`}
                      >
                        <img
                          src={product.image}
                          alt={product.title}
                          className={style.image}
                        />
                      </Link>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
              <div
                className={`${style.iconContainer} ${style.rightIcon}`}
                onClick={() => swiperRef.current.swiper.slideNext()}
              >
                <svg className={style.icon} viewBox="0 0 24 24">
                  <path d="M15.4,9.88,10.81,5.29a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42L14,11.29a1,1,0,0,1,0,1.42L9.4,17.29a1,1,0,0,0,1.41,1.42l4.59-4.59A3,3,0,0,0,15.4,9.88Z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div className={style.largeMargin}>
      {categories["lavoro-in-pillole"] && categories["lavoro-in-pillole"]?.posts?.nodes?.length > 0 && (
        <div className="container">
          <div className={style.doubleSideFlex}>
            <h2 className={style.sectionTitle}>
              {categories["lavoro-in-pillole"]?.name}
            </h2>
            <a
              className={`${style.moreArticles} d-none d-md-block`}
              href={categories["lavoro-in-pillole"]?.uri}
            >
              Vedi altre pillole
            </a>
            <a
              className={`${style.moreArticles} d-md-none`}
              href={categories["lavoro-in-pillole"]?.uri}
            >
              Vedi altro
            </a>
          </div>
          <Slider
            className="row"
            more={categories["lavoro-in-pillole"]?.uri}
            moreSrc="/assets/images/lavoro-in-pillole.jpg"
          >
            {categories["lavoro-in-pillole"]?.posts?.nodes?.slice(0, 3)
              .map((data, index) => (
                <div key={index} className="col-12 col-md-4 ">
                  <BigCardVertical data={data} key={data.slug} />
                </div>
              ))}
          </Slider>
        </div>
      )}
      </div>
      <div className={style.largeMargin}>
        {categories["storie-e-iniziative-beautystar"] && categories["storie-e-iniziative-beautystar"]?.posts?.nodes?.length > 0 && (
          <div className="container">
            <div className={style.doubleSideFlex}>
              <h2 className={style.sectionTitle}>
                {categories["storie-e-iniziative-beautystar"]?.name}
              </h2>
              <Link
                className={`${style.moreArticles} d-none d-md-block`}
                to={categories["storie-e-iniziative-beautystar"]?.uri}
              >
                Vedi altre storie e iniziative
              </Link>
              <Link
                className={`${style.moreArticles} d-md-none`}
                to={categories["storie-e-iniziative-beautystar"]?.uri}
              >
                Vedi altro
              </Link>
            </div>
            <Slider
              className={style.grid}
              more={categories["storie-e-iniziative-beautystar"]?.uri}
            >
              <div className={style.first}>
                {categories["storie-e-iniziative-beautystar"]?.posts?.nodes[0] ? (
                  <GreyCardHorizontal
                    data={
                      categories["storie-e-iniziative-beautystar"]?.posts?.nodes[0]
                    }
                  />
                ) : null}
              </div>
              <div className={style.second}>
                {categories["storie-e-iniziative-beautystar"]?.posts?.nodes[1] ? (
                  <GreyCardHorizontal
                    data={
                      categories["storie-e-iniziative-beautystar"]?.posts?.nodes[1]
                    }
                  />
                ) : null}
              </div>
              <div className={style.third}>
                {categories["storie-e-iniziative-beautystar"]?.posts?.nodes[2] ? (
                  <GreyCardVertical
                    data={
                      categories["storie-e-iniziative-beautystar"]?.posts?.nodes[2]
                    }
                  />
                ) : null}
              </div>
              <div className={style.forth}>
                {categories["storie-e-iniziative-beautystar"]?.posts?.nodes[3] ? (
                  <GreyCardVertical
                    data={
                      categories["storie-e-iniziative-beautystar"]?.posts?.nodes[3]
                    }
                  />
                ) : null}
              </div>
            </Slider>
          </div>
        )}
      </div>
      <div className="container">
        <a
          // href=""
          target="blank"
          onClick={() => {
            fetch(
              `${CLICK_TRACKING_API_URL}/${userId}?orig_title=Laborability%20FOR%20DMO&orig_url=${window.location.href}&dest_url=https://saas.hrzucchetti.it/hrpdmospa/jsp/login.jsp?cpccchk=0.45511375613608207`
            )
              .then((response) => response.json())
              .catch((err) => console.log(err));
            window
              .open(
                "https://saas.hrzucchetti.it/hrpdmospa/jsp/login.jsp?cpccchk=0.45511375613608207",
                "_blank"
              )
              .focus();
          }}
        >
          <img
            src="/assets/images/banners/ZucchettiDesktop.png?v=1"
            className={`${style.zucchettiBanner} d-none d-lg-block`}
          />
          <img
            src="/assets/images/banners/ZucchettiMobile.png"
            className={`${style.zucchettiBanner} d-block d-lg-none`}
          />
        </a>
      </div>
      {categories["nuovi-ingressi-in-azienda-beautystar-novita"] &&
       categories["nuovi-ingressi-in-azienda-beautystar-novita"].posts.nodes.length > 0 &&
       specialCategories["prodotti-beautystar"] && (
        <div className={`${style.productSectionContainer} ${style.largeMargin}`}>
          <ProductSection
            sliderData={
              categories["nuovi-ingressi-in-azienda-beautystar-novita"]?.posts?.nodes
            }
            product={specialCategories["prodotti-beautystar"]?.posts.nodes[0]}
          />
        </div>
       )}
      <div className={style.largeMargin}>
        <div className="container">
          <div className={style.doubleSideFlex}>
            <div />
            <span
              className={style.moreArticles}
              target="_blank"
              onClick={() => {
                fetch(
                  `${CLICK_TRACKING_API_URL}/${userId}?orig_title=Laborability%20FOR%20DMO&orig_url=${window.location.href}&dest_url=https%3A%2F%2Fwww.dmospa.it%2F%23lavora-con-noi`
                )
                  .then((response) => response.json())
                  .catch((err) => console.log(err));
                window
                  .open("https://www.dmospa.it/#lavora-con-noi", "_blank")
                  .focus();
              }}
            >
              Vedi tutte le posizioni aperte
            </span>
          </div>
        </div>
        <div className={style.jobsContainer}>
          <Jobs jobs={data.jobs.nodes} />
        </div>
      </div>
      <div className="container">
        <div className={`${style.largeMargin} ${style.tfrWrapper}`}>
          <div className={style.doubleSideFlex}>
            <h2 className={style.sectionTitle}>Guida TFR</h2>
          </div>
          <div className="row">
            <div
              className={`${style.imageContainer} col-lg-6 mt-5 mt-sm-0 mb-4 mb-lg-0`}
            >
              <div className={style.imageWrapper}>
                <img
                  src="/assets/images/formazione-tfr-mobile.png"
                  alt="Tfr mobile"
                  className="d-block d-lg-none m-auto"
                />
                <img
                  src="/assets/images/formazione-tfr-desktop.png"
                  alt="Tfr desktop"
                  className="d-none d-lg-block"
                />
              </div>
            </div>
            <div className="col-lg-5 text-center text-lg-start mt-2 mt-lg-0">
              <p className={`${style.title} mb-2 mb-lg-4`}>
                Guida al TFR, per investirlo in modo più consapevole
              </p>
              <p className={`${style.content} d-none d-lg-block`}>
                Al termine della carriera o alla fine di ogni esperienza
                lavorativa, ciascun lavoratore ha diritto a ricevere il TFR, il{" "}
                <b>trattamento di fine rapporto</b>. In alcuni casi si tratta di
                somme importanti, a maggior ragione se la carriera lavorativa è
                intercorsa per molti anni con una stessa azienda. Il lavoratore
                può decidere, sin dall’inizio,{" "}
                <b>dove e come custodire o investire il TFR</b>.
              </p>
              <Link
                className={`${style.articoloButton} btn btn-lg btn-block mt-4`}
                to="/strumenti/guida-tfr"
              >
                Inizia la formazione
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        {categories["novita-beautystar"] && categories["novita-beautystar"]?.posts?.nodes?.length > 0 && (
          <div className={style.largeMargin}>
            <div className={style.doubleSideFlex}>
              <h2 className={style.sectionTitle}>
                {categories["novita-beautystar"]?.name}
              </h2>
              <Link
                className={style.moreArticles}
                to={categories["novita-beautystar"]?.uri}
              >
                Vedi altre novità
              </Link>
            </div>
            <Slider
              className="row"
              moreSrc="/assets/images/novita.jpg"
              more={categories["novita-beautystar"]?.uri}
            >
              {categories["novita-beautystar"]?.posts?.nodes?.slice(0, 3)
                .map((data, index) => (
                  <div key={index} className="col-12 col-md-4">
                    <BigCardVertical data={data} key={data.slug} />
                  </div>
                ))}
            </Slider>
          </div>
        )}
        {/* <img
          src='/assets/images/banner-1-beautystar-mobile.png'
          alt='Banner 1'
          className={`${style.banner} d-lg-none`}
        />
        <img
          src='/assets/images/banner-1-beautystar.png'
          alt='Banner 1'
          className={`${style.banner} d-none d-lg-block`}
        /> */}
      </div>
      <div className={style.largeMargin}>
        {categories["fondazione"] && categories["fondazione"]?.posts?.nodes?.length > 0 && (
          <div className="container">
            <div className={style.doubleSideFlex}>
              <h2 className={style.sectionTitle}>
                {categories["fondazione"]?.name}
              </h2>
              <a
                className={`${style.moreArticles} d-none d-md-block`}
                href={categories["fondazione"]?.uri}
              >
                Vedi altre notizie sulla fondazione
              </a>
              <a
                className={`${style.moreArticles} d-md-none`}
                href={categories["fondazione"]?.uri}
              >
                Vedi altro
              </a>
            </div>
            <Slider
              className="row"
              more={categories["fondazione"].uri}
              moreSrc="/assets/images/fondazione.jpg"
            >
              {categories["fondazione"]?.posts?.nodes?.slice(0, 4)
                .map((data, index) => (
                  <div
                    key={index}
                    className="col-12 col-md-6 mb-md-5 mb-lg-0 col-lg-3 "
                  >
                    <BigCardVertical data={data} key={data.slug} />
                  </div>
                ))}
            </Slider>
          </div>
        )}
      </div>
      <div className="container">
        <div className={`${style.largeMargin} ${style.assegnoUnicoWrapper}`}>
          <div className={style.doubleSideFlex}>
            <h2 className={style.sectionTitle}>Assegno Unico</h2>
          </div>
          <div className="row">
            <div
              className={`${style.imageContainer} col-lg-6 mt-5 mt-sm-0 mb-4 mb-lg-0`}
            >
              <div className={style.imageWrapper}>
                <img
                  src="/assets/images/assegno-unico-mobile.png"
                  alt="Assegno unico mobile"
                  className="d-block d-lg-none m-auto"
                />
                <img
                  src="/assets/images/assegno-unico-desktop.png"
                  alt="Assegno unico desktop"
                  className="d-none d-lg-block"
                />
              </div>
            </div>
            <div className="col-lg-5 text-center text-lg-start mt-2 mt-lg-0">
              <p className={`${style.title} mb-2 mb-lg-4`}>
                Assegno unico e universale: <br /> istruzioni per l’uso
              </p>
              <p className={`${style.content} d-none d-lg-block`}>
                Abbiamo deciso di realizzare una guida per rendere ogni persona
                capace di richiedere in completa autonomia l'Assegno Unico
                Familiare 2022. Ci siamo messi nei panni dei molti genitori che
                provano a compilare la domanda online, chiedendoci se i passaggi
                fossero tutti chiari, se il linguaggio utilizzato fosse semplice
                e accessibile.
              </p>
              <Link
                className={`${style.articoloButton} btn btn-lg btn-block btn-primary mt-4`}
                to="/strumenti/guida-assegno-unico-universale"
              >
                Vai alla guida
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className={style.largeMargin}>
        <div className="container">
          <div className={style.doubleSideFlex}>
            <h2 className={style.sectionTitle}>Video</h2>
            <Link className={style.moreArticles} to="/video">
              Vedi altri video
            </Link>
          </div>
          <div className="row">
            {stickyVideos.slice(0, 3).map((item, index) => (
              <div key={index} className="col-12 pb-5 col-lg-4 pb-lg-0">
                <VideoCard data={item?.node} />
              </div>
            ))}
          </div>
        </div>
      </div>
      <BlueSlider />
      <div className={style.gray}>
        {categories["mondo-hr"] && categories["mondo-hr"]?.posts?.nodes?.length > 0 && (
          <div className="container">
            <div className={style.doubleSideFlex}>
              <h2 className={style.sectionTitle}>
                {categories["mondo-hr"]?.name}
              </h2>
              <Link
                className={style.moreArticles}
                to={categories["mondo-hr"]?.uri}
              >
                Vedi altri contenuti
              </Link>
            </div>
            <Slider
              className="row"
              more={categories["mondo-hr"]?.uri}
              moreSrc="/assets/images/mondo-hr.jpg"
            >
              {categories["mondo-hr"]?.posts?.nodes?.slice(0, 3)
                .map((data, index) => (
                  <div key={index} className="col-12 col-md-4">
                    <BigCardVertical data={data} />
                  </div>
                ))}
            </Slider>
          </div>
        )}
        <div className={style.preFooterContainer}>
          <div className="container">
            <div className="row justify-strech">
              <div className="col-12 col-lg-4">
                <div className="d-flex align-items-center">
                  <div className={style.preFooterContent}>
                    <p className={style.preFooterTitle}>Portali</p>
                    <p className={style.preFooterDescription}>
                      I link utili ai portali Zucchetti, Formazione E-learning e
                      alle posizioni aperte nel Gruppo DMO, per averli sempre a
                      portata di click.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-8">
                <div className={style.logosContainer}>
                  <div className={style.logoCard}>
                    <a
                      target="_blank"
                      // href=""
                      rel="noreferrer"
                      onClick={() => {
                        fetch(
                          `${CLICK_TRACKING_API_URL}/${userId}?orig_title=Laborability%20FOR%20DMO&orig_url=${window.location.href}&dest_url=https://eur02.safelinks.protection.outlook.com/?url=https%3A%2F%2Fsaas.hrzucchetti.it%2Fhrpdmospa%2Fjsp%2Flogin.jsp&data=04%7C01%7Csimone.fluperi%40tsw.it%7C95e54889beb84e586b5308d9a397be0b%7C546af60286b642c68452cdb39b0fd64e%7C1%7C0%7C637720694396302150%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C1000&sdata=SkU5b8qKvWtVUDDRMexBKY5y2LadjVgumxmJjsTn458%3D&reserved=0`
                        )
                          .then((response) => response.json())
                          .catch((err) => console.log(err));
                        window
                          .open(
                            "https://eur02.safelinks.protection.outlook.com/?url=https%3A%2F%2Fsaas.hrzucchetti.it%2Fhrpdmospa%2Fjsp%2Flogin.jsp&data=04%7C01%7Csimone.fluperi%40tsw.it%7C95e54889beb84e586b5308d9a397be0b%7C546af60286b642c68452cdb39b0fd64e%7C1%7C0%7C637720694396302150%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C1000&sdata=SkU5b8qKvWtVUDDRMexBKY5y2LadjVgumxmJjsTn458%3D&reserved=0",
                            "_blank"
                          )
                          .focus();
                      }}
                    >
                      <img
                        src="/assets/svgs/zucchetti.svg"
                        alt="Logo"
                        className={style.logo}
                      />
                    </a>
                  </div>
                  <div className={style.logoCard}>
                    <a
                      target="_blank"
                      href="https://elearning.dmospa.it/"
                      rel="noreferrer"
                    >
                      <img
                        src="/assets/svgs/logo-elearning.svg"
                        alt="Logo"
                        className={style.logo}
                      />
                    </a>
                  </div>
                  <div className={style.logoCard}>
                    <a
                      target="_blank"
                      href="https://www.dmospa.it/#lavora-con-noi"
                      rel="noreferrer"
                    >
                      <img
                        src="/assets/svgs/positions.svg"
                        alt="Logo"
                        className={style.logo}
                      />
                    </a>
                  </div>
                  <div className={`${style.logoCard} ${style.logoText}`}>
                    <a
                      target="_blank"
                      href="https://jo.my/dmowelfarebutton"
                      rel="noreferrer"
                    >
                      Welfare <br />
                      pubblico
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
